<!-- 加入华都 -->

<template>
  <div class="intoHuadu-container">
    <navSecondPageVue type="JoinHuadu"></navSecondPageVue>
  </div>
  <Tabs :routerProps="routerProps" v-if="showTab"></Tabs>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" @hideTab="hideTab"></component>
    </keep-alive>
  </router-view>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import navSecondPageVue from '../components/navSecondPage.vue'
import Tabs from '../components/Tabs.vue'
const routerProps = [
  // {
  //   name: '人才理念',
  //   path: '/JoinHuadu/People'
  // },
  {
    name: '招聘动态',
    path: '/JoinHuadu/Job'
  },
  {
    name: '联系我们',
    path: '/JoinHuadu/Contact'
  }
]
const showTab = ref(true)

const route = useRoute()
const hideTab = () => {
  showTab.value = false
}
watch(
  () => route.fullPath,
  () => {
    if (route.meta.hideTab) {
      return
    }
    showTab.value = true
  }
)
</script>
<style lang="stylus" scoped></style>
